import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Ob Filter-Optionen, System-Einstellungen oder ein Dark-Mode-Schalter – wahrscheinlich seid ihr im Internet
schon mal auf einen Toggle-Button gestoßen. Diese erscheinen zunächst recht simpel: Sie erlauben den
Nutzer:innen zwischen zwei Zuständen zu wechseln, meistens „ein“ und „aus“.`}</p>
    <p>{`Leider gibt es kein natives HTML-Element für Toggle-Buttons. Aus diesem Grund geistern zahlreiche,
selbstgebaute Implementierungen im Internet herum. Einige davon, ihr ahnt es schon, völlig unzugängliche Ungetüme.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAACBf/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABMjjbaZAk/8QAGxAAAgMAAwAAAAAAAAAAAAAAAgMAAREEEhP/2gAIAQEAAQUCU4fJRiJv5JWyjyd8llt//8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Bp//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EAB0QAAEDBQEAAAAAAAAAAAAAABEAAQIQEiIxQYH/2gAIAQEABj8CtlzSMnl6sHDVIX//xAAbEAADAQADAQAAAAAAAAAAAAAAAREhMUFhcf/aAAgBAQABPyGy5rsgh59S5aNtV59GNIioy+Nl5lP/2gAMAwEAAgADAAAAEJQ//8QAGBEAAgMAAAAAAAAAAAAAAAAAABEBMVH/2gAIAQMBAT8QWbHh/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Qh//EAB4QAQACAQQDAAAAAAAAAAAAAAEAESExUZGxQWGh/9oACAEBAAE/EM8oFrY2759Tx0s8qaFGz1KdcAVy+wBazC6a1FRTNXC4NaNp/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Ein Mann steht hinter einem DJ Pult mit vielen Knöpfen und Drehschaltern.",
          "title": "Ein Mann steht hinter einem DJ Pult mit vielen Knöpfen und Drehschaltern.",
          "src": "/static/89c49ec501cf8d714d0b60d7428a8f5f/e5166/pexels-gaby-tenda-dj-desk.jpg",
          "srcSet": ["/static/89c49ec501cf8d714d0b60d7428a8f5f/f93b5/pexels-gaby-tenda-dj-desk.jpg 300w", "/static/89c49ec501cf8d714d0b60d7428a8f5f/b4294/pexels-gaby-tenda-dj-desk.jpg 600w", "/static/89c49ec501cf8d714d0b60d7428a8f5f/e5166/pexels-gaby-tenda-dj-desk.jpg 1200w", "/static/89c49ec501cf8d714d0b60d7428a8f5f/b17f8/pexels-gaby-tenda-dj-desk.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © Gaby Tenda / pexels.com`}</em></p>
    <p>{`Ich habe eine `}<a parentName="p" {...{
        "href": "https://alexlehner86.github.io/punk-api-beer-list/"
      }}>{`Demo-Applikation`}</a>{` in React mit barrierefreien
Toggle-Buttons erstellt, die ich euch zeigen möchte. Ich bin mir nicht sicher, ob meine Lösung optimal ist. Daher würde
ich mich über euer Feedback freuen.`}</p>
    <h2>{`Demo: Bier-Liste mit Toggle-Buttons`}</h2>
    <p>{`Meine Demo zeigt eine Liste verschiedener Biere. Nutzer:innen können über Toggle-Buttons zwischen schwachen und starken
Bieren wechseln. Probiert es aus:`}</p>
    <iframe src="https://alexlehner86.github.io/punk-api-beer-list/" title="Demo-Seite Bier-Liste mit stark/schwach Toggle-Buttons" loading="lazy"></iframe>
    <p>{`Wenn ihr den Code betrachtet, werdet ihr eine Gruppe von Buttons vorfinden, denen ich
die `}<InlineCode mdxType="InlineCode">{`switch`}</InlineCode>{` Rolle zugewiesen habe. Bevor wir uns auf die technischen Details stürzen,
möchte ich noch erklären, was ich mit dem Bedienelement erreichen möchte.`}</p>
    <h2>{`Ziele und Anforderungen`}</h2>
    <ol>
      <li parentName="ol">{`Das Bedienelement soll seine Funktionalität klar vermitteln, sowohl visuell als auch für assistive
Technologien.`}</li>
      <li parentName="ol">{`Es soll seinen aktuellen Zustand vermitteln, sowohl visuell als auch für assistive Technologien.`}</li>
      <li parentName="ol">{`Nutzer:innen sollen das Bedienelement allein mit der Tastatur bedienen können.`}</li>
      <li parentName="ol">{`Nur eine Option („schwach“ oder „stark“) kann gleichzeitig aktiv sein. Wenn eine Option aktiviert wird,
wird die andere deaktiviert.`}</li>
      <li parentName="ol">{`Der Wechsel zwischen den beiden Optionen soll ein bewusster Akt der Nutzer:innen sein und nicht aus
Versehen passieren.`}</li>
    </ol>
    <h2>{`Semantische Auszeichnung und Tastaturbedienung`}</h2>
    <p>{`Man könnte auch Radio-Buttons verwenden und ihr Styling mit CSS anpassen. Ich habe mich bewusst dagegen
entschieden, weil diese bei Fokussierung automatisch aktiviert werden (was ich nicht will!) und zu den
Standard-Formularelementen zählen. Web-Formulare sollen aber keinen Kontext-Wechsel auslösen, solange
Nutzer:innen das Formular nicht bewusst abschicken.`}</p>
    <p>{`Also habe ich mich dafür entschieden, ein `}<InlineCode mdxType="InlineCode">{`button`}</InlineCode>{` Element für jede auswählbare
Option („schwach“ und „stark“) zu verwenden und ihnen die `}<InlineCode mdxType="InlineCode">{`switch`}</InlineCode>{` Rolle zuzuweisen.
Die `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/wai-aria-1.2/#switch"
      }}>{`Spezifikation`}</a>{` dieser Rolle lautet:`}</p>
    <blockquote lang="en">
    A type of checkbox that represents on/off values, as opposed to checked/unchecked values.
    </blockquote>
    <p>{`Meine `}<a parentName="p" {...{
        "href": "https://github.com/alexlehner86/punk-api-beer-list/tree/main/src/components/TwoStateSwitch"
      }}>{`React-Komponente`}</a>{`
beinhaltet einen Toggle-Button für jede Option. Auf diese Weisekann ich sie individuell beschriften und ihren Zustand
(ein oder aus) eindeutig vermitteln. Weiters gruppiere ich die beiden Buttons in einem `}<InlineCode mdxType="InlineCode">{`section`}</InlineCode>{`
Element mit `}<InlineCode mdxType="InlineCode">{`group`}</InlineCode>{` Rolle. Seht euch meinen JSX-Code an:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<section className={styles.container} role="group" aria-label={switchLabel}>
    <p>{switchLabel}:</p>
    <div className={styles.switchWrapper}>
        <button
            type="button"
            role="switch"
            aria-checked={selectedState === stateValues[0]}
            onClick={toggleSwitch}
        >
            {stateLabels[0]}
        </button>
        <button
            type="button"
            role="switch"
            aria-checked={selectedState === stateValues[1]}
            onClick={toggleSwitch}
        >
            {stateLabels[1]}
        </button>
    </div>
</section>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Nutzer:innen können die Buttons mit der Tab-Taste fokussieren und sie mit der Enter- oder Leertaste ein- bzw. ausschalten.`}</p>
    <h2>{`Individuelles Styling mit CSS`}</h2>
    <p>{`Das Styling der Toggle-Buttons sorgt für die visuelle Vermittlung ihrer Funktionalität und ihres aktuellen Zustands.
Wenn eine Option ausgewählt ist, invertiere ich ihre Farben und füge eine vertikale Linie mithilfe
des `}<InlineCode mdxType="InlineCode">{`::after`}</InlineCode>{` Pseudo-elements hinzu. Hier ein Auszug aus der SCSS-Datei meiner Komponente:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`/* Invert background and text colors */
button[aria-checked="true"] {
    background: var(--light-black);
    color: white;
}
/* Add vertical line to highlight selected option */
button[aria-checked="true"]:after {
    content: "";
    position: absolute;
    bottom: 0.2em;
    top: 0.2em;
}
button[aria-checked="true"]:first-of-type:after {
    right: 0.2em;
    border-right: 0.3em solid var(--primary-color);
}
button[aria-checked="true"]:last-of-type:after {
    left: 0.2em;
    border-left: 0.3em solid var(--primary-color);
}`}</code>{`
        `}</deckgo-highlight-code>
    <h2>{`Feedback`}</h2>
    <p>{`Das war's. Mein Vorschlag für barrierefreie Toggle-Buttons. Was sagt ihr dazu? Genial oder einfach furchtbar?
Sagt mir eure Meinung auf Twitter, Mastodon oder GitHub!`}</p>
    <h2>{`Nützliche Links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/switch_role"
        }}>{`ARIA: switch role (MDN)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/TR/wai-aria-1.2/#switch"
        }}>{`WAI-ARIA 1.2 (Spezifikation)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://joshcollinsworth.com/blog/accessible-toggle-buttons"
        }}>{`Building Accessible Toggle Buttons (Alternative Implementierung von Josh Collinsworth)`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      